import { JIDO_SEISEI_EFAX, SINGLE_PERMISSION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { attrs: { "title": "Efax\u9001\u4FE1", "ok-text": "\u9001\u4FE1", "cancel-text": "\u30AD\u30E3\u30F3\u30BB\u30EB", "visible": _vm.isVisible, "ok-button-props": { props: { disabled: !_vm.isSendSingleEfaxPermission } } }, on: { "cancel": _vm.handleCancel, "ok": _vm.handleOK } }, [_c("a-spin", { attrs: { "spinning": _vm.isFetching, "size": "large" } }, [_c("text-input", { key: "sendTo", attrs: { "form-item": "", "rules": "required", "label": "\u5B9B\u5148", "span": 24, "value": _vm.selectedRecord.fax }, on: { "change": function($event) {
    return _vm.updateEfaxForm("fax", $event);
  } } }), _c("text-input", { key: "carbonCopy", attrs: { "form-item": "", "label": "C C(C)", "span": 24 }, on: { "change": function($event) {
    return _vm.updateEfaxForm("cc", $event);
  } } }), _c("text-input", { key: "subject", attrs: { "form-item": "", "label": "\u4EF6\u540D(U)", "span": 24, "value": _vm.selectedRecord.fileAttachment }, on: { "change": function($event) {
    return _vm.updateEfaxForm("sub", $event);
  } } }), _c("div", { staticClass: "mb-4" }, [_c("p", { staticClass: "mb-0" }, [_vm._v("\u4EF6\u540D(U)")]), _c("div", { staticClass: "file-container" }, [_c("a-icon", { staticClass: "mr-2 icon-file", attrs: { "type": "file-excel" } }), _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.selectedRecord.fileAttachment))])], 1)]), _c("text-area", { key: "emailContent", attrs: { "label": "\u672C\u6587", "placeholder": "\u672C\u6587", "span": 24 }, on: { "change": function($event) {
    return _vm.updateEfaxForm("body", $event);
  } } })], 1)], 1);
};
var staticRenderFns = [];
var SendSingleFileModal_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".file-container[data-v-4efa4289]{margin-top:5px;width:100%;display:flex;justify-content:start;align-items:center;border:1px solid #bec6d0;padding:8px}.file-container .icon-file[data-v-4efa4289]{color:#15b982;font-size:25px}\n")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "SendSingleFileModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    recordSelected: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      apiUrl,
      selectedRecord: {},
      isFetching: false,
      page: JIDO_SEISEI_EFAX,
      permissions: {
        single: SINGLE_PERMISSION
      }
    };
  },
  computed: {
    isSendSingleEfaxPermission() {
      return this.$can(this.permissions.single, this.page);
    }
  },
  watch: {
    recordSelected(newVal) {
      if (newVal) {
        this.selectedRecord = newVal;
      }
    }
  },
  methods: {
    handleCancel() {
      this.isFetching = false;
      this.$emit("closed-modal", false);
    },
    updateEfaxForm(key, value) {
      let obj = { ...this.selectedRecord };
      obj = Object.assign(obj, { [key]: value });
      this.selectedRecord = obj;
    },
    async handleOK() {
      try {
        this.isFetching = true;
        const payload = {
          to: this.selectedRecord.fax,
          cc: this.selectedRecord.cc || "",
          subject: this.selectedRecord.fileAttachment,
          body: this.selectedRecord.body || "",
          fileAttachment: this.selectedRecord.fileAttachment
        };
        const res = await this.axios.post(`${this.apiUrl}/jidoseisei/efaxs/${this.selectedRecord.id}/single`, payload);
        if (res && res.data) {
          setTimeout(() => {
            this.alertResult("success", res.data.message);
            this.$emit("refresh-page", true);
            this.handleCancel();
          }, 2e3);
        }
      } catch (e) {
        this.handleCancel();
        this.alertResult("error", e.response.data.message);
      }
    },
    alertResult(type, message) {
      this.$notification[type]({
        message: message || "Errors encountered!"
      });
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "4efa4289", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var SendSingleFileModal = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { SendSingleFileModal as default };
